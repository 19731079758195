import * as Sentry from '@sentry/sveltekit'
import { PUBLIC_SENTRY_DSN, PUBLIC_VERCEL_ENV } from '$env/static/public'

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    Sentry.feedbackIntegration({
      triggerLabel: 'Help',
      formTitle: 'Give Feedback',
      submitButtonLabel: 'Submit Feedback',
      messagePlaceholder: ''
    })
  ],
  environment: PUBLIC_VERCEL_ENV
})

document.addEventListener('error', (e) => {
  const target = e.target as HTMLElement
  if (target.tagName === 'IMG') {
    const img = target as HTMLImageElement

    // Add breadcrumb separately
    Sentry.addBreadcrumb({
      type: 'error',
      category: 'image',
      message: `Failed to load image: ${img.src}`,
      level: 'error'
    })

    Sentry.captureException(new Error('Image failed to load'), {
      contexts: {
        image: {
          src: img.src
        }
      },
      tags: {
        type: 'image_load_failure'
      }
    })
  }
}, true)

export const handleError = Sentry.handleErrorWithSentry()
