import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [3,4,5,6,12,8,9,11];

export const dictionary = {
		"/": [~14],
		"/(backend)/admin": [~21,[3,4]],
		"/(backend)/admin/clients": [~22,[3,4]],
		"/(backend)/admin/users": [~23,[3,4]],
		"/(backend)/admin/users/create": [~25,[3,4]],
		"/(backend)/admin/users/[id]": [~24,[3,4]],
		"/(backend)/broker": [~26,[3]],
		"/(backend)/broker/benefits/[benefitId=objectid]/specs": [~28,[3,5]],
		"/(backend)/broker/benefits/[benefitId=objectid]/[planId=objectid]/[[section=number]]": [~27,[3,5]],
		"/(backend)/broker/clients": [~29,[3]],
		"/(backend)/broker/clients/new": [~37,[3]],
		"/(backend)/broker/clients/[clientId=objectid]": [~30,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits": [~31,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits/[period=benefitPeriod]": [32,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits/[period=benefitPeriod]/specs": [~33,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/edit": [~36,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources": [~34,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources/[resourceType=resourceType]": [~35,[3,6]],
		"/(backend)/broker/delivery": [~38,[3]],
		"/(backend)/broker/events": [~39,[3]],
		"/(backend)/broker/messages": [~40,[3]],
		"/(backend)/broker/messages/create": [~42,[3]],
		"/(backend)/broker/messages/[id]": [~41,[3]],
		"/(backend)/broker/profile": [~43,[3]],
		"/(backend)/broker/vendors": [~44,[3]],
		"/(backend)/broker/vendors/new": [~46,[3]],
		"/(backend)/broker/vendors/[id=objectid]": [~45,[3]],
		"/builder/[slug]/[period=benefitPeriod]": [~57,[12]],
		"/dev/presentation-test": [58,[13]],
		"/(auth)/forgot-password": [~15,[2]],
		"/(auth)/login": [~16,[2]],
		"/(auth)/logout": [~17,[2]],
		"/(backend)/(terms)/privacy": [~19,[3]],
		"/(auth)/reset-password/[token]": [~18,[2]],
		"/specs/[slug]/[period=benefitPeriod]": [~59],
		"/(backend)/(terms)/terms": [~20,[3]],
		"/(participants)/[[lang]]/v/[slug]": [~47,[7,8,9]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/authorize": [~55,[7,8,9,10]],
		"/(participants)/[[lang]]/v/[slug]/[benefitId=objectid]": [~56,[7,8,9]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]": [~48,[7,8,9,10,11]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/contacts": [~52,[7,8,9,10,11]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/documents": [~53,[7,8,9,10,11]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/enrollment": [~54,[7,8,9,10,11]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome/[[messageId]]": [~51,[7,8,9,10,11]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/[section=benefitSection]/[[videoKey=videoKey]]": [~50,[7,8,9,10,11]],
		"/(participants)/[[lang]]/v/[slug]/[[code=code]]/[period=benefitPeriod]/[messageType=messageType]/[[messageId=objectid]]": [~49,[7,8,9,10,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';